// src/templates/posts/Article.js

import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import Layout from '../../components/Layout.js'
import PageTitle from '../../components/PageTitle'
import ResourceMetadata from '../../components/ResourceMetadata'
import ResourceHero from '../../components/ResourceHero.js'
import ResourceCards from '../../components/ResourceCards.js'
import AllLayouts from '../../components/AllLayouts.js'

function Article({ data }) {
  const postData = data.wpArticle
  const layoutBlocks = postData.pageBuilder.layoutBlocks || []

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let postTags = []

      postData.article.relatedTopics.map((node, index) =>
        postTags.push(node.name)
      )

      const gtmArgs = {
        pageType: 'article',
        publishDate: postData.date,
        tags: postTags,
      }

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(gtmArgs)
    }
  }, [postData])

  return (
    <Layout>
      <Seo post={postData} />
      <article className="space-y-responsive-xl-48">
        <section className="article-title text-center">
          <PageTitle
            label="Article"
            title={postData.title}
            url={postData.uri}
          />

          <div className="container">
            <ResourceHero heroImage={postData.article.heroImage} />
          </div>

          {(postData.article.author || postData.article.relatedTopics) && (
            <ResourceMetadata
              author={postData.article.author}
              relatedTopics={postData.article.relatedTopics}
              date={postData.date}
            />
          )}
        </section>

        {layoutBlocks.map((layout, index) => {
          return <AllLayouts key={index} layoutData={layout} />
        })}

        <section className="related-resources">
          <div className="container">
            <h2 className="mb-responsive-xl-32 border-b border-granite-200 pb-responsive-xl-8 text-center text-sm font-medium uppercase tracking-wide">
              Related Resources
            </h2>
            <ResourceCards
              manualResources={postData.article.relatedResources}
              automatedResources={data.allWpArticle}
            />
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default Article

export const query = graphql`
  query ($id: String!) {
    wpArticle(id: { eq: $id }) {
      seo {
        ...SeoFragment
      }
      title
      date(formatString: "M/D/YYYY")
      pageBuilder {
        ...ArticleBuilderFragment
      }
      article {
        author {
          ... on WpBio {
            id
            uri
            bio {
              firstName
              lastName
            }
          }
        }
        description
        relatedTopics {
          uri
          name
        }
        heroImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
        relatedResources {
          ... on WpArticle {
            ...ArticleResourceFragment
          }
          ... on WpPodcast {
            ...PodcastResourceFragment
          }
          ... on WpReport {
            ...ReportResourceFragment
          }
          ... on WpWebinar {
            ...WebinarResourceFragment
          }
        }
      }
      uri
    }
    allWpArticle(
      filter: { id: { ne: $id } }
      limit: 3
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...ArticleResourceFragment
        }
      }
    }
  }
`
